import { useRef, useEffect, useState } from 'react'

import useApiUrl from '@/store/useApiUrl'
import useLoadedModels from '@/store/useLoadedModels'
import useModelLoader from '@/store/useModelLoader'

import { setObject3DUserData } from '@/core/utils'
import { fixObject3dUsuals } from '@/core/utils'

import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const FileInput = ({ setMeshes }) => {
  const { apiUrl } = useApiUrl()
  const { setLoadedModels } = useLoadedModels()
  const { modelLoader } = useModelLoader()

  const [isLoading, setIsLoading] = useState(false)

  const fileInputRef = useRef(null)
  const importTypeRef = useRef('room')

  const handleFileInputChange = async (event) => {
    const file1 = event.target.files[0]
    if (!file1) return console.error('No file selected in file input to add to the scene.')
    setIsLoading(true)

    try {
      const file1URL = URL.createObjectURL(file1)
      const file1Ext = file1.name.split('.').pop()

      if (file1Ext === 'usdz' && false) {
        // upload usdz file
        const formData = new FormData()
        formData.append('file', file1)
        const response = await fetch(`${apiUrl}/convert/model/usd-to-glb`, {
          method: 'POST',
          body: formData,
        }).catch((error) => {
          console.error('Error:', error)
        })

        console.log('Success:', response)
        // after getting glb url load glb
        // put in scene
      } else if (file1Ext === 'glb' || file1Ext === 'gltf') {
        const loadedModel = await modelLoader.load(file1URL, file1Ext)
        if (!loadedModel) return console.error('Error loading model')
        fixObject3dUsuals(loadedModel)
        setObject3DUserData(loadedModel, file1.name, importTypeRef.current)
        setLoadedModels(loadedModel)
        const newlyAddedMesh = loadedModel.clone()
        newlyAddedMesh.userData.hash += `-${newlyAddedMesh.id}`
        setMeshes((prevModels) => [...prevModels, newlyAddedMesh])
      } else if (file1Ext === 'obj' || file1Ext === 'mtl') {
        const file2 = event.target.files[1]
        if (file1Ext === 'obj' && !file2) {
          const loadedModel = await modelLoader.loadOBJ(file1URL)
          if (!loadedModel) return console.error('Error loading model')
          fixObject3dUsuals(loadedModel)
          setObject3DUserData(loadedModel, file1.name, importTypeRef.current)
          setLoadedModels(loadedModel)
          const newlyAddedMesh = loadedModel.clone()
          newlyAddedMesh.userData.hash += `-${newlyAddedMesh.id}`
          setMeshes((prevModels) => [...prevModels, newlyAddedMesh])
        } else if (file1Ext === 'obj' && file2) {
          const file2Ext = file2.name.split('.').pop()
          if (file2Ext === 'mtl') {
            const mtlURL = URL.createObjectURL(file2)
            const loadedModel = await modelLoader.loadOBJMTL(file1URL, mtlURL)
            if (!loadedModel) return console.error('Error loading model')
            fixObject3dUsuals(loadedModel)
            setObject3DUserData(loadedModel, file1.name, importTypeRef.current)
            setLoadedModels(loadedModel)
            const newlyAddedMesh = loadedModel.clone()
            newlyAddedMesh.userData.hash += `-${newlyAddedMesh.id}`
            setMeshes((prevModels) => [...prevModels, newlyAddedMesh])
            URL.revokeObjectURL(mtlURL)
          }
        } else if (file1Ext === 'mtl' && !file2) {
          console.warn('only loading an mtl file, obj required!')
        } else if (file1Ext === 'mtl' && file2) {
          const file2Ext = file2.name.split('.').pop()
          if (file2Ext === 'obj') {
            const objURL = URL.createObjectURL(file2)
            const loadedModel = await modelLoader.loadOBJMTL(objURL, file1URL)
            if (!loadedModel) return console.error('Error loading model')
            fixObject3dUsuals(loadedModel)
            setObject3DUserData(loadedModel, file2.name, importTypeRef.current)
            setLoadedModels(loadedModel)
            const newlyAddedMesh = loadedModel.clone()
            newlyAddedMesh.userData.hash += `-${newlyAddedMesh.id}`
            setMeshes((prevModels) => [...prevModels, newlyAddedMesh])
            URL.revokeObjectURL(objURL)
          }
        }
      }

      URL.revokeObjectURL(file1URL)
    } catch (error) {
      console.error('Error loading model:', error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fileInputRef.current.addEventListener('change', handleFileInputChange)
    return () => fileInputRef.current.removeEventListener('change', handleFileInputChange)
  }, [fileInputRef.current])

  return (
    <section className="import-3d-model-from-device">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="w-full" variant="secondary">
            Import Model
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>
            <p className="text-center">Type</p>
            <p className="text-xs text-gray-500 text-center">GLB, GLTF, OBJ.</p>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => ((importTypeRef.current = 'room'), fileInputRef.current.click())}>
            Room
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => ((importTypeRef.current = 'furniture'), fileInputRef.current.click())}>
            Furniture
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Input ref={fileInputRef} type="file" multiple accept=".gltf,.glb,.obj,.mtl" className="hidden" />
    </section>
  )
}

export default FileInput
