import { Box3, Mesh, Vector3 } from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'

export const generateHash = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0 // Convert to 32-bit integer
  }
  return Math.abs(hash).toString(16) // Convert to hash
}

/**
 * moves the given camera to make the dimensions of the object3D and it's decendants fit in the frustum
 * @param {THREE.object3D} object3D
 * @param {THREE.PerspectiveCamera} camera
 */
export const autoFitobject3DToCameraFrustum = (object3D, camera) => {
  const box = new Box3().setFromObject(object3D)
  const boxSize = box.getSize(new Vector3())
  const boxCenter = box.getCenter(new Vector3())

  const maxDim = Math.max(boxSize.x, boxSize.y, boxSize.z) // Find the largest dimension
  const fov = camera.fov * (Math.PI / 180) // Convert FOV from degrees to radians

  // Calculate the distance required for the camera to fit the object in the frustum
  const distance = maxDim / (2 * Math.tan(fov / 2))
  const offset = 1.25

  camera.position.set(boxCenter.x * offset, boxCenter.y, boxCenter.z + distance * offset)
  camera.lookAt(boxCenter)
  camera.updateProjectionMatrix()
}

/**
 * traverses the THREE.Object3D to merge all geometries and materials
 * @param {THREE.Object3D} object3D with random meshes as children
 * @return {THREE.Mesh} THREE.Mesh with all the merged geometries and materials
 */
export const mergeMeshes = (object3D) => {
  const geometries = []
  const materials = []
  const materialIndices = []

  object3D.traverse((child) => {
    if (child.isMesh) {
      const geometry = child.geometry.clone()
      geometry.applyMatrix4(child.matrixWorld)
      geometries.push(geometry)
      materials.push(child.material)
      materialIndices.push(materials.length - 1)
    }
  })

  const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries, false)

  let offset = 0
  const groups = geometries.map((geometry, index) => {
    const count = geometry.index ? geometry.index.count : geometry.attributes.position.count
    const group = { start: offset, count: count, materialIndex: materialIndices[index] }
    offset += count
    return group
  })

  groups.forEach((group) => {
    mergedGeometry.addGroup(group.start, group.count, group.materialIndex)
  })

  return new Mesh(mergedGeometry, materials)
}

export const fixObject3dUsuals = (object3D) => {
  const object3DBox = new Box3().setFromObject(object3D)
  const object3DSize = object3DBox.getSize(new Vector3())
  const object3DCenter = object3DBox.getCenter(new Vector3())

  // 1 unit in Threejs is 1 meter in real life, Average room is 3 meters high. Therefore, if object3D is shorter than 3.3 meters it is probably in meters
  // object3D most probably is using mm
  if (object3DSize.y > 100) object3D.scale.set(0.001, 0.001, 0.001)
  // object3D most probably is using inches
  else if (object3DSize.y > 33.3) object3D.scale.set(0.01, 0.01, 0.01)
  // object3D most probably is using cm
  else if (object3DSize.y > 3.3) object3D.scale.set(0.1, 0.1, 0.1)

  // offset model so that its bottom center is at (0,0,0)
  object3D.position.set(-object3DCenter.x, object3DSize.y * 0.5 - object3DCenter.y, -object3DCenter.z)
}

export const setObject3DUserData = (object3D, fileName, furnitureType) => {
  object3D.userData.filename = fileName.split('.').slice(0, -1).join('.')
  object3D.userData.type = furnitureType
  const meshHash = generateHash(object3D.userData.filename + Date.now() + object3D.id)
  object3D.userData.hash = meshHash
  object3D.traverse((descendant) => {
    if (descendant.isMesh) {
      descendant.userData.hash = meshHash
      descendant.userData.type = furnitureType
    }
  })
}
