import { useEffect, useState } from 'react'

import useModelLoader from '../../store/useModelLoader'
import useLoadedModels from '../../store/useLoadedModels'

import { setObject3DUserData } from '@/core/utils'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { LoadingSpinner } from '@/components/ui/loading-spinner'
import { Label } from '@/components/ui/label'

const SketchfabButton = ({ setMeshes }) => {
  const CLIENT_ID = `UgnZASR1Cmi5BOwOs0KZVcSUfiLisfJiP9h25Xpk`
  const AUTHENTICATION_URL = `https://sketchfab.com/oauth2/authorize/?state=123456789&response_type=token&client_id=${CLIENT_ID}`

  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [token, setToken] = useState(null)
  const { modelLoader } = useModelLoader()
  const { setLoadedModels } = useLoadedModels()

  const checkToken = () => {
    //check if the sketchfab token is expired
    const tokenExpiresIn = localStorage.getItem('sketchfab_token_expires_in')
    if (tokenExpiresIn) {
      const nowInMins = Date.now() / 1000
      if (nowInMins > tokenExpiresIn - 60) {
        localStorage.removeItem('sketchfab_token')
        localStorage.removeItem('sketchfab_token_expires_in')
      }
    }
    // User redirected from Sketchfab with parameters
    const url = new URL(window.location)
    const hashParams = url.hash.split('&')
    for (let param of hashParams) {
      if (param.indexOf('access_token') !== -1) {
        const token = param.replace('#access_token=', '')
        localStorage.setItem('sketchfab_token', token)
      }
      if (param.indexOf('expires_in') !== -1) {
        const expiresIn = Number(param.replace('expires_in=', ''))
        localStorage.setItem('sketchfab_token_expires_in', Date.now() / 1000 + expiresIn)
      }
    }

    // Load token from local storage
    setToken(localStorage.getItem('sketchfab_token'))
  }
  useEffect(() => {
    checkToken()
  }, [])

  const sketchfabLoginHandler = () => {
    window.open(AUTHENTICATION_URL, 'Sketchfab', 'width=800,height=600')
  }

  const loadSketchfabModelById = async () => {
    if (!document.querySelector('#modelId').value) return

    setLoading(true)
    try {
      const modelUrlResponse = await fetch(
        `https://api.sketchfab.com/v3/models/${document.querySelector('#modelId').value}/download`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!modelUrlResponse.ok) {
        throw new Error(`Error: ${modelUrlResponse.status} - ${modelUrlResponse.statusText}`)
      }

      const modelUrlData = await modelUrlResponse.json()

      const modelResponse = await fetch(modelUrlData.glb.url)
      if (!modelResponse.ok) throw new Error(`Error: ${modelResponse.statusText}`)
      const blob = await modelResponse.blob()
      const glbUrl = URL.createObjectURL(blob)
      const fileExt = `glb`

      const loadedModel = await modelLoader.load(glbUrl, fileExt)
      setObject3DUserData(loadedModel, modelResponse.url, 'furniture')
      setLoadedModels(loadedModel)
      const newlyAddedMesh = loadedModel.clone()
      newlyAddedMesh.userData.hash += `-${newlyAddedMesh.id}`
      setMeshes((prevModels) => [...prevModels, newlyAddedMesh])
      console.log(loadedModel)
      console.log(newlyAddedMesh)
      setDialogOpen(false)
    } catch (error) {
      console.error('Loading Sketchfab model failed:', error)
    }
    setLoading(false)
  }

  return (
    <>
      {!token ? (
        <Button variant="secondary" onClick={() => sketchfabLoginHandler()}>
          Login to Sketchfab
        </Button>
      ) : (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogTrigger asChild>
            <Button variant="secondary">Import from Sketchfab</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-neutral-50">Import a 3D model from Sketchfab</DialogTitle>
              <DialogDescription>Enter the Sketchfab model id below to import the 3D model.</DialogDescription>
            </DialogHeader>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="modelId" className="text-right text-neutral-50">
                Model ID
              </Label>
              <Input variant="outline" id="modelId" className="col-span-3 text-neutral-50" />
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Cancel
                </Button>
              </DialogClose>
              <Button type="submit" onClick={() => loadSketchfabModelById()}>
                {loading ? <LoadingSpinner /> : `Import`}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default SketchfabButton
