import { create } from 'zustand'

import { PeerState } from '../enums/peer'

const usePeerState = create((set) => ({
  peerState: PeerState.INACTIVE,
  setPeerState: (newPeerState) => set({ peerState: newPeerState }),
}))

export default usePeerState
