import { useEffect, useState } from 'react'

import useApiUrl from '../../store/useApiUrl'
import useScene from '../../store/useScene'
import usePeerState from '../../store/usePeerState'
import useLoadedModels from '../../store/useLoadedModels'
import { PeerState } from '../../enums/peer'

import Exporter from '../../core/Exporter.js'
import MeshSnapShooter from '../../core/MeshSnapShooter.js'
import FileInput from './FileInput.jsx'
import SketchfabButton from './SketchfabButton.jsx'
import ModelIcon from './ModelIcon.jsx'

import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/hooks/use-toast'

import io from 'socket.io-client'
import { LoadingSpinner } from '../ui/loading-spinner'

const meshSnapShooter = new MeshSnapShooter()
const exporter = new Exporter()

export default function SidePanel({
  socketio,
  setSocketio,
  userId,
  setPeerIdToCall,
  roomId,
  setRoomId,
  setMeshes,
  measurementsActive,
  setMeasurementsActive,
}) {
  const { apiUrl } = useApiUrl()
  const { setPeerState } = usePeerState()
  const { scene } = useScene()
  const { loadedModels } = useLoadedModels()
  const { toast } = useToast()

  const [isCollabLoading, setIsCollabLoading] = useState(false)
  const [modelIcons, setModelIcons] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const genCollabLinkOnClickHandle = async () => {
    if (!userId.current) {
      toast({ description: "^.^ Somehow you don't have a user id!" })
      return console.warn('No userId')
    }
    if (!loadedModels.length) {
      toast({ description: 'Preload all the required models in the side panel before collaborating.' })
      return console.warn('No loaded models in the memorySotrage/SidePanel')
    }

    setIsCollabLoading(true)

    const formData = new FormData()
    formData.append('userId', userId.current)
    for (const model of loadedModels) {
      await exporter.exportMeshToGLBAndPutInFormData(model, formData)
    }

    setRoomId(userId.current)
    setSocketio(io(apiUrl))

    await fetch(`${apiUrl}/upload/models`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data)
      })
      .catch((error) => {
        console.error('Error:', error)
      })

    // traverse the scene and generate url params
    let urlParams = `?roomId=${userId.current}`
    scene.traverse((object3D) => {
      // having a type => is a model
      if (object3D.userData.type) {
        urlParams += `&${object3D.userData.hash}=${encodeURIComponent(
          JSON.stringify(object3D.parent.parent.matrix.elements)
        )}`
      }
    })

    navigator.clipboard
      .writeText(`${window.location.href.split('?')[0]}${urlParams}`)
      .then(() => {
        toast({ description: 'Your collab link has been copied to the clipboard. Share it to collaborate!' })
      })
      .catch((err) => {
        console.error('Failed to copy room ID:', err)
      })

    setIsCollabLoading(false)
  }

  const leaveCollabRoom = () => {
    socketio.emit('leaveRoom', roomId)
    setSocketio(null)
    setRoomId(null)
  }

  const IsSceneEmpty = () => {
    let pivotPresent = false
    scene.traverse((child) => {
      if (child.name == 'pivot') pivotPresent = true
    })
  }

  useEffect(() => {
    if (!loadedModels.length) return

    // take a picture of the mesh and add it to the side panel
    setModelIcons((prevModelIcons) => [
      ...prevModelIcons,
      meshSnapShooter.getPngOfMesh(loadedModels[loadedModels.length - 1]),
    ])
  }, [loadedModels])

  return (
    <section className="w-full h-full py-2 px-2 z-20 flex flex-col flex-wrap gap-2 justify-between justify-items-center bg-black text-white">
      <header className="w-full flex flex-col">
        <figure className="flex flex-row justify-center">
          <a href="https://www.inovise.ai" target="_blank">
            <img className="w-32 h-16" src="icons/white_logo_transparent_background.png" alt="inovise logo" />
          </a>
        </figure>
        <h1 className="text-center text-3xl font-bold">Planovise</h1>
      </header>

      <article className="w-full flex flex-col gap-1">
        <FileInput setMeshes={setMeshes} />
        <SketchfabButton setMeshes={setMeshes} />
      </article>

      <article className="w-full grow flex flex-col">
        <div className="flex flex-row flex-wrap justify-start justify-items-start gap-2">
          {modelIcons &&
            modelIcons.map((modelIcon, modelIndex) => (
              <ModelIcon
                socketio={socketio}
                key={modelIndex}
                userId={userId}
                roomId={roomId}
                modelIndex={modelIndex}
                modelIcon={modelIcon}
                setMeshes={setMeshes}
              />
            ))}
        </div>
      </article>

      <article className="w-full flex flex-col gap-1">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button className="text-wrap" variant="secondary">
              Video Call
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => setPeerState(PeerState.ACTIVE_BY_BUTTON_CLICK)}>
              Generate Video Call Code
            </DropdownMenuItem>

            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
              <DialogTrigger asChild>
                <Button variant="ghost" className="w-full p-2">
                  <p className="w-full text-left">Join Video Call Via Code</p>
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle className="text-neutral-50">Enter code to join video call</DialogTitle>
                  <DialogDescription>Enter the code that you recieved from the host.</DialogDescription>
                </DialogHeader>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="remote-peer-id" className="text-right text-neutral-50">
                    Video Call Code
                  </Label>
                  <Input variant="outline" id="remote-peer-id" className="col-span-3 text-neutral-50" />
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button type="button" variant="secondary">
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    type="submit"
                    onClick={() => {
                      setPeerIdToCall(document.getElementById('remote-peer-id').value)
                      setPeerState(PeerState.ACTIVE_BY_ENTERING_CODE)
                      setDialogOpen(false)
                    }}
                  >
                    Join
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <DropdownMenuItem onClick={() => setPeerState(PeerState.INACTIVE)}>Leave Video Call</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        {roomId && socketio ? (
          <Button className="text-wrap" variant="secondary" onClick={() => leaveCollabRoom()}>
            Leave Collab
          </Button>
        ) : (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button className="text-wrap" variant="secondary" onClick={() => genCollabLinkOnClickHandle()}>
                  {isCollabLoading ? <LoadingSpinner /> : 'Collaborate'}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Generate Collaborative Room Planning Link</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          // <Button className="text-wrap" variant="secondary" onClick={() => genCollabLinkOnClickHandle()}>
          //   Generate Collaborative Room Planning Link
          // </Button>
        )}
        <Button className="text-wrap" variant="secondary" onClick={() => setMeasurementsActive(!measurementsActive)}>
          {measurementsActive ? 'Hide' : 'Show'} Measurements
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button className="text-wrap" variant="secondary">
              Export Scene
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>
              <p className="text-center">Format</p>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => {
                if (IsSceneEmpty) {
                  toast({ description: 'There are no models in the scene.' })
                  return console.warn('USDZ Export failed: Scene is empty.')
                }
                exporter.exportModel('usdz', scene)
              }}
            >
              USDZ
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                if (IsSceneEmpty) {
                  toast({ description: 'There are no models in the scene.' })
                  return console.warn('GLB Export failed: Scene is empty.')
                }
                exporter.exportModel('glb', scene)
              }}
            >
              GLB
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                if (IsSceneEmpty) {
                  toast({ description: 'There are no models in the scene.' })
                  return console.warn('GLTF Export failed: Scene is empty.')
                }
                exporter.exportModel('gltf', scene)
              }}
            >
              GLTF
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                if (IsSceneEmpty) {
                  toast({ description: 'There are no models in the scene.' })
                  return console.warn('OBJ Export failed: Scene is empty.')
                }
                exporter.exportModel('obj', scene)
              }}
            >
              OBJ
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </article>

      <footer>
        <figure className="w-full flex justify-center">
          <a href="https://apps.apple.com/ca/app/scanovise/id6472953989" target="_blank">
            <img src="icons/Scanovise.jpeg" alt="Scanovise logo" width={102} height={102} />
          </a>
        </figure>
        <p className="text-center text-xs">Copyright © {new Date().getFullYear()} Inovise Inc. All rights reserved.</p>
      </footer>
    </section>
  )
}
