import { useEffect, useMemo, useRef, useState } from 'react'
import { ArrowHelper, Box3, Matrix4, Raycaster, Vector3 } from 'three'
import { PivotControls } from '@react-three/drei'

import Mouse from '../../core/Mouse'

const mouse = new Mouse()
let lastMoved = 0
const throttleTime = 40 // ms

const MeshWithPivot = ({
  socketio,
  userId,
  roomId,
  meshes,
  mesh,
  activeMeshId,
  setActiveMeshId,
  orbitCtrlRef,
  setMeasurementRulerPosXVisibility,
  setMeasurementRulerNegXVisibility,
  setMeasurementRulerPosZVisibility,
  setMeasurementRulerNegZVisibility,
  setMeasurementRulerPosX,
  setMeasurementRulerNegX,
  setMeasurementRulerPosZ,
  setMeasurementRulerNegZ,
  setHeightRulerPos,
  setWidthRulerPos,
  setDepthRulerPos,
}) => {
  const pivotRef = useRef()

  const object3DBoundingBox = useMemo(() => new Box3().setFromObject(mesh))
  const object3DCenter = useMemo(() => new Vector3(0, 0, 0))
  const rayOriginV3 = useMemo(() => new Vector3(0, 0, 0))

  const calculateBoundingRulers = () => {
    object3DBoundingBox.setFromObject(mesh)
    setHeightRulerPos([
      new Vector3(object3DBoundingBox.max.x, object3DBoundingBox.min.y, object3DBoundingBox.max.z),
      new Vector3(object3DBoundingBox.max.x, object3DBoundingBox.max.y, object3DBoundingBox.max.z),
    ])
    setWidthRulerPos([
      new Vector3(object3DBoundingBox.min.x, object3DBoundingBox.min.y, object3DBoundingBox.max.z),
      new Vector3(object3DBoundingBox.max.x, object3DBoundingBox.min.y, object3DBoundingBox.max.z),
    ])
    setDepthRulerPos([
      new Vector3(object3DBoundingBox.max.x, object3DBoundingBox.min.y, object3DBoundingBox.min.z),
      new Vector3(object3DBoundingBox.max.x, object3DBoundingBox.min.y, object3DBoundingBox.max.z),
    ])
  }

  const handleMeshClick = (e) => {
    e.stopPropagation() // to prevent selecting the last mesh intersected
    if (mouse.didMouseDrag()) return // to prevent selecting the mesh while dragging (mainly for pivot controls and orbit controls)
    calculateBoundingRulers()
    setActiveMeshId(mesh.userData.hash)
  }

  useEffect(() => {
    if (mesh.userData.hash === activeMeshId) {
      calculateBoundingRulers()
    }
  }, [activeMeshId])

  const handleAfterRender = () => {
    // apply matrix from url paramter(room collab feature)
    if (mesh.userData.matrix) {
      mesh.parent.parent.matrix.fromArray(mesh.userData.matrix)
      mesh.userData.matrix = null
    }
  }

  const raycasterRef = useRef(new Raycaster())
  raycasterRef.current.far = 36
  const intersects = useRef([])
  const lastV3AboveRoom = useRef(new Vector3(0, 0, 0))
  let i = 0

  const positiveXDir = useRef(new Vector3(1, 0, 0)) // right
  const negativeXDir = useRef(new Vector3(-1, 0, 0)) // left
  const positiveZDir = useRef(new Vector3(0, 0, 1)) // front
  const negativeZDir = useRef(new Vector3(0, 0, -1)) // back
  const negativeYDir = useRef(new Vector3(0, -1, 0)) // down
  const PositiveYDir = useRef(new Vector3(0, 1, 0)) // up

  const handlePivotDrag = (local) => {
    calculateBoundingRulers()
    object3DBoundingBox.getCenter(object3DCenter)

    // ray from object3DPosXCenter -> positive x
    rayOriginV3.set(object3DBoundingBox.max.x, object3DCenter.y, object3DCenter.z)
    raycasterRef.current.set(rayOriginV3, positiveXDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.isMesh) {
          setMeasurementRulerPosXVisibility(true)
          setMeasurementRulerPosX([rayOriginV3.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerPosXVisibility(false)
        }
      }
    } else {
      setMeasurementRulerPosXVisibility(false)
    }

    // ray from object3DNegXCenter -> negative x
    rayOriginV3.set(object3DBoundingBox.min.x, object3DCenter.y, object3DCenter.z)
    raycasterRef.current.set(rayOriginV3, negativeXDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.isMesh) {
          setMeasurementRulerNegXVisibility(true)
          setMeasurementRulerNegX([rayOriginV3.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerNegXVisibility(false)
        }
      }
    } else {
      setMeasurementRulerNegXVisibility(false)
    }

    // ray from object3DPosZCenter -> positive z
    rayOriginV3.set(object3DCenter.x, object3DCenter.y, object3DBoundingBox.max.z)
    raycasterRef.current.set(rayOriginV3, positiveZDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.isMesh) {
          setMeasurementRulerPosZVisibility(true)
          setMeasurementRulerPosZ([rayOriginV3.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerPosZVisibility(false)
        }
      }
    } else {
      setMeasurementRulerPosZVisibility(false)
    }

    // ray from object3DNegZCenter -> negative z
    rayOriginV3.set(object3DCenter.x, object3DCenter.y, object3DBoundingBox.min.z)
    raycasterRef.current.set(rayOriginV3, negativeZDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.isMesh) {
          setMeasurementRulerNegZVisibility(true)
          setMeasurementRulerNegZ([rayOriginV3.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerNegZVisibility(false)
        }
      }
    } else {
      setMeasurementRulerNegZVisibility(false)
    }

    if (mesh.userData.type === 'furniture' && false) {
      // ray from object3DNegYCenter -> negative y
      rayOriginV3.set(object3DCenter.x, object3DBoundingBox.min.y - 0.01, object3DCenter.z)
      raycasterRef.current.set(rayOriginV3, negativeYDir.current)
      intersects.current = raycasterRef.current.intersectObjects(meshes)
      if (intersects.current.length > 0) {
        console.log(intersects.current)
        for (i = 0; i < intersects.current.length; i++) {
          if (intersects.current[i].object.userData.type === 'room') {
            lastV3AboveRoom.current.copy(intersects.current[i].point)
            break
          }
        }
      }

      // ray from object3DNegYCenter -> positive y
      rayOriginV3.set(object3DCenter.x, object3DBoundingBox.min.y, object3DCenter.z)
      raycasterRef.current.set(rayOriginV3, PositiveYDir.current)
      intersects.current = raycasterRef.current.intersectObjects(meshes)
      if (intersects.current.length > 0) {
        for (i = 0; i < intersects.current.length; i++) {
          if (intersects.current[i].object.userData.type === 'room') {
            // prevent from going below the room floor
            pivotRef.current.matrix.setPosition(lastV3AboveRoom.current)
            break
          }
        }
      }
    }

    const now = Date.now()
    if (now - lastMoved < throttleTime) return
    lastMoved = now
    if (roomId) {
      socketio.emit('itemMove', {
        sender: userId.current,
        roomId: roomId,
        hash: mesh.userData.hash,
        matrix4Array: local.elements,
      })
    }
  }

  const handlePivotDragStart = () => {
    orbitCtrlRef.current.enabled = false // to prevent orbiting while dragging mesh
  }

  const handlePivotDragEnd = () => {
    orbitCtrlRef.current.enabled = true
    setMeasurementRulerPosXVisibility(false)
    setMeasurementRulerNegXVisibility(false)
    setMeasurementRulerPosZVisibility(false)
    setMeasurementRulerNegZVisibility(false)
  }

  const handlePivotClick = (e) => {
    e.stopPropagation() // to prevent selecting the last mesh intersected
  }

  return (
    <>
      <PivotControls
        ref={pivotRef}
        name="pivot"
        lineWidth={1.5}
        depthTest={false}
        visible={activeMeshId === mesh.userData.hash}
        disableAxes={activeMeshId !== mesh.userData.hash}
        disableRotations={activeMeshId !== mesh.userData.hash}
        disableScaling={activeMeshId !== mesh.userData.hash}
        disableSliders={activeMeshId !== mesh.userData.hash}
        onDragStart={handlePivotDragStart}
        onDragEnd={handlePivotDragEnd}
        onDrag={handlePivotDrag}
        onClick={handlePivotClick}
      >
        <primitive object={mesh} castShadow receiveShadow onClick={handleMeshClick} onAfterRender={handleAfterRender} />
      </PivotControls>
      {/* <box3Helper args={[object3DBoundingBox]} /> */}
    </>
  )
}

export default MeshWithPivot
