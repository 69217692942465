import { useEffect, useRef } from 'react'

import { PeerState } from '../../enums/peer'
import usePeerState from '../../store/usePeerState'

import Peer from 'peerjs'
import { useToast } from '@/hooks/use-toast'

const PeerToPeerStream = ({ peerIdToCall, setPeerIdToCall }) => {
  const { peerState, setPeerState } = usePeerState()

  const peerRef = useRef(null)
  const remoteVideoRef = useRef(null)

  const { toast } = useToast()

  const stopStreaming = (mediaStream) => {
    mediaStream.getTracks().forEach((track) => track.stop())
  }

  const destroyPeer = () => {
    if (peerRef.current) {
      remoteVideoRef.current.style.display = 'none'
      setPeerState(PeerState.INACTIVE)
      setPeerIdToCall(null)
      peerRef.current.destroy()
      peerRef.current = null
    }
  }

  useEffect(() => {
    if (peerState === PeerState.ACTIVE_BY_BUTTON_CLICK || peerState === PeerState.ACTIVE_BY_ENTERING_CODE) {
      if (!peerRef.current) peerRef.current = new Peer()

      peerRef.current.on('open', async (myPeerId) => {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((localMediaStream) => {
            if (peerState === PeerState.ACTIVE_BY_BUTTON_CLICK) {
              navigator.clipboard
                .writeText(myPeerId)
                .then(() => {
                  toast({ description: 'Your video conference code has been copied to the clipboard.' })
                })
                .catch((err) => {
                  console.error('Failed to copy peer ID:', err)
                  toast({ description: 'Failed to generate a code, please try again.' })
                  stopStreaming(localMediaStream)
                  destroyPeer()
                })
            } else if (peerState === PeerState.ACTIVE_BY_ENTERING_CODE) {
              const call = peerRef.current.call(peerIdToCall, localMediaStream)

              call.on('stream', (remoteStream) => {
                remoteVideoRef.current.style.display = 'block'
                remoteVideoRef.current.srcObject = remoteStream
              })
              call.on('close', () => {
                stopStreaming(localMediaStream)
                destroyPeer()
              })
              call.on('disconnected', () => {
                stopStreaming(localMediaStream)
                destroyPeer()
              })
              call.on('error', () => {
                stopStreaming(localMediaStream)
                destroyPeer()
              })
            }

            peerRef.current.on('call', (call) => {
              call.answer(localMediaStream)

              call.on('stream', (remoteStream) => {
                remoteVideoRef.current.style.display = 'block'
                remoteVideoRef.current.srcObject = remoteStream
              })
              call.on('close', () => {
                destroyPeer()
                stopStreaming(localMediaStream)
              })
              call.on('disconnected', () => {
                destroyPeer()
                stopStreaming(localMediaStream)
              })
              call.on('error', () => {
                destroyPeer()
                stopStreaming(localMediaStream)
              })
            })
          })
          .catch((err) => {
            console.error('Failed to get local stream:', err)
            toast({ description: 'Failed to start the call, please try again.' })
            destroyPeer()
          })
      })

      peerRef.current.on('close', () => {
        destroyPeer()
      })
      peerRef.current.on('disconnected', () => {
        destroyPeer()
      })
      peerRef.current.on('error', () => {
        destroyPeer()
      })
    } else if (peerState === PeerState.INACTIVE && peerRef.current) {
      destroyPeer()
    }
  }, [peerState])

  return (
    <>
      <video
        ref={remoteVideoRef}
        className="z-999 absolute bottom-6 right-6"
        style={{ display: 'none', width: '300px' }}
        autoPlay
      ></video>
    </>
  )
}

export default PeerToPeerStream
