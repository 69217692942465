import { MeshBasicMaterial } from 'three'
// import { USDZLoader } from 'three/examples/jsm/loaders/USDZLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
// import { useLoader } from '@react-three/fiber'

export default class ModelsLoader {
  constructor() {
    this.basicMaterial = new MeshBasicMaterial()
    this.gltfLoader = new GLTFLoader()
    this.objLoader = new OBJLoader()
    this.mtlLoader = new MTLLoader()
  }

  async load(blobURL, fileExt) {
    switch (fileExt) {
      // case 'usdz':
      //   return this.loadUSDZ(blobURL)
      case 'glb':
        return this.loadGLB(blobURL)
      case 'gltf':
        return this.loadGLTF(blobURL)
      case 'obj':
        return this.loadOBJ(blobURL)
      default:
        console.warn(`format not supported: ${fileExt}`)
        break
    }
  }

  // It doesn't work on most cases, Three.js only supports loading compressed usdz and not usda, usdb, usdc & usdz
  // loadUSDZ(filePath) {
  //   return useLoader(USDZLoader, filePath)
  // }

  loadGLB(filePath) {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(filePath, (gltf) => {
        resolve(gltf.scene.children[0])
      })
    })
  }

  loadGLTF(filePath) {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(filePath, (gltf) => {
        resolve(gltf.scene.children[0])
      })
    })
  }

  loadOBJ(filePath) {
    return new Promise((resolve, reject) => {
      this.objLoader.load(filePath, (object3D) => {
        object3D.traverse(function (child) {
          if (child.isMesh) {
            child.material = this.basicMaterial
          }
        })
        resolve(object3D)
      })
    })
  }

  loadOBJMTL(objURL, mtlURL) {
    return new Promise((resolve, reject) => {
      this.mtlLoader.load(mtlURL, (mtl) => {
        mtl.preload()
        this.objLoader.setMaterials(mtl)
        this.objLoader.load(objURL, (object3D) => {
          resolve(object3D)
        })
      })
    })
  }
}
