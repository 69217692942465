import { useEffect, useRef, useState } from 'react'
import { Vector3 } from 'three'
import { Environment, OrbitControls } from '@react-three/drei'

import useApiUrl from '../../store/useApiUrl'
import useModelLoader from '../../store/useModelLoader'
import useLoadedModels from '../../store/useLoadedModels'
import useScene from '@/store/useScene'

import MeshWithPivot from './MeshWithPivot'
import DistanceMeasurement from './DistanceMeasurements'

import io from 'socket.io-client'

const RoomPlanner = ({
  socketio,
  setSocketio,
  userId,
  roomId,
  setRoomId,
  meshes,
  setMeshes,
  activeMeshId,
  setActiveMeshId,
  measurementsActive,
}) => {
  const { apiUrl } = useApiUrl()
  const { modelLoader } = useModelLoader()
  const { loadedModels, setLoadedModels } = useLoadedModels()
  const { scene } = useScene()

  const orbitCtrlRef = useRef(null)

  const [measurementRulerPosXVisibility, setMeasurementRulerPosXVisibility] = useState(false)
  const [measurementRulerNegXVisibility, setMeasurementRulerNegXVisibility] = useState(false)
  const [measurementRulerPosZVisibility, setMeasurementRulerPosZVisibility] = useState(false)
  const [measurementRulerNegZVisibility, setMeasurementRulerNegZVisibility] = useState(false)
  const [measurementRulerPosX, setMeasurementRulerPosX] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])
  const [measurementRulerNegX, setMeasurementRulerNegX] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])
  const [measurementRulerPosZ, setMeasurementRulerPosZ] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])
  const [measurementRulerNegZ, setMeasurementRulerNegZ] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])

  // height/width/depth measurement rulers for the mesh, placed on the right-front side
  const [heightRulerPos, setHeightRulerPos] = useState([
    new Vector3(), // start
    new Vector3(), // end
  ])
  const [widthRulerPos, setWidthRulerPos] = useState([
    new Vector3(), // start
    new Vector3(), // end
  ])
  const [depthRulerPos, setDepthRulerPos] = useState([
    new Vector3(), // start
    new Vector3(), // end
  ])

  const fetchModelsAndSetMeshes = async (urlParams, roomId) => {
    // call the server for the filenames
    fetch(`${apiUrl}/load/modelsFilenamesBy?userId=${roomId}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then(async (data) => {
        // load and cache the models with their proper name
        const loadPromises = data.files.map((fileName) => {
          const fileURL = `./models/users/${roomId}/${fileName}`
          const fileExt = fileName.split('.').pop()
          return modelLoader.load(fileURL, fileExt)
        })

        const loadedServerModels = await Promise.all(loadPromises)
        loadedServerModels.forEach((loadedServerModel) => {
          setLoadedModels(loadedServerModel)
        })

        for (let p of urlParams) {
          if (p[0] == 'roomId') continue

          const urlUniqueModelHash = p[0]
          const urlStoredModelHash = p[0].split('-')[0]
          const itemMatrix = JSON.parse(decodeURIComponent(p[1]))
          loadedServerModels.forEach((storedModel) => {
            if (storedModel.userData.hash == urlStoredModelHash) {
              const clonedModel = storedModel.clone()
              clonedModel.userData.matrix = itemMatrix
              clonedModel.userData.hash = urlUniqueModelHash
              setMeshes((prevMeshes) => [...prevMeshes, clonedModel])
            }
          })
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  // room collab feature: client opening a link with a roomId
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('roomId')) {
      const paramRoomId = urlParams.get('roomId')
      if (!roomId) {
        setRoomId(paramRoomId)
        setSocketio(io(apiUrl))
        fetchModelsAndSetMeshes(urlParams, paramRoomId)
        // TODO: on models load, send message to get new positions from the other peer
      }
    }
  }, [])

  useEffect(() => {
    if (socketio && roomId) socketio.emit('joinRoom', roomId)
  }, [socketio, roomId])

  useEffect(() => {
    if (socketio) {
      socketio.on('itemAdded', (data) => {
        if (data.sender == userId.current) return // don't add your own items
        loadedModels.forEach((storedModel) => {
          if (storedModel.userData.hash == data.hash.split('-')[0]) {
            const clonedModel = storedModel.clone()
            clonedModel.userData.hash = data.hash
            setMeshes((prevMeshes) => [...prevMeshes, clonedModel])
          }
        })
      })

      socketio.on('itemMoved', (data) => {
        if (data.sender == userId.current) return

        scene.traverse((child) => {
          if (child.userData.hash == data.hash) {
            child.parent.parent.matrix.fromArray(data.matrix4Array)
          }
        })
      })

      socketio.on('itemDeleted', (data) => {
        if (data.sender == userId.current) return

        setMeshes((prevMeshes) => prevMeshes.filter((mesh) => mesh.userData.hash !== data.hash))
        setActiveMeshId(0)
      })

      return () => {
        socketio.off('itemAdded')
        socketio.off('itemMoved')
        socketio.off('itemDeleted')
      }
    }
  }, [socketio, loadedModels])

  useEffect(() => {
    const handleKeyDown = (event) => {
      // DEL KEY
      if (event.key === 'Delete' && activeMeshId) {
        setMeshes((prevMeshes) => prevMeshes.filter((mesh) => mesh.userData.hash !== activeMeshId))
        setActiveMeshId(0)

        if (roomId && socketio) {
          socketio.emit('itemDelete', {
            sender: userId.current,
            roomId: roomId,
            hash: activeMeshId,
          })
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [roomId, socketio, activeMeshId])

  // whenever we add/remove a mesh select the last one added and give it a unique hash
  useEffect(() => {
    if (meshes.length) {
      setActiveMeshId(meshes[meshes.length - 1].userData.hash)
    }
  }, [meshes])

  return (
    <>
      <Environment files="./environment-map/venice_sunset_1k.hdr" background={true} backgroundBlurriness={0.5} />
      <OrbitControls ref={orbitCtrlRef} minPolarAngle={-Math.PI} maxPolarAngle={Math.PI} />
      <ambientLight intensity={0.8} />
      <directionalLight intensity={1.0} position={[10, 4, 8]} castShadow />
      <gridHelper args={[16, 16]} />

      {meshes.map((mesh) => (
        <MeshWithPivot
          key={mesh.id}
          socketio={socketio}
          userId={userId}
          roomId={roomId}
          meshes={meshes}
          mesh={mesh}
          orbitCtrlRef={orbitCtrlRef}
          activeMeshId={activeMeshId}
          setActiveMeshId={setActiveMeshId}
          setMeasurementRulerPosXVisibility={setMeasurementRulerPosXVisibility}
          setMeasurementRulerNegXVisibility={setMeasurementRulerNegXVisibility}
          setMeasurementRulerPosZVisibility={setMeasurementRulerPosZVisibility}
          setMeasurementRulerNegZVisibility={setMeasurementRulerNegZVisibility}
          setMeasurementRulerPosX={setMeasurementRulerPosX}
          setMeasurementRulerNegX={setMeasurementRulerNegX}
          setMeasurementRulerPosZ={setMeasurementRulerPosZ}
          setMeasurementRulerNegZ={setMeasurementRulerNegZ}
          setHeightRulerPos={setHeightRulerPos}
          setWidthRulerPos={setWidthRulerPos}
          setDepthRulerPos={setDepthRulerPos}
        />
      ))}

      {measurementsActive && measurementRulerPosXVisibility && (
        <DistanceMeasurement
          start={measurementRulerPosX[0]}
          end={measurementRulerPosX[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 512" width="22px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}
      {measurementsActive && measurementRulerNegXVisibility && (
        <DistanceMeasurement
          start={measurementRulerNegX[0]}
          end={measurementRulerNegX[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 512" width="22px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}
      {measurementsActive && measurementRulerPosZVisibility && (
        <DistanceMeasurement
          start={measurementRulerPosZ[0]}
          end={measurementRulerPosZ[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 512" width="22px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}
      {measurementsActive && measurementRulerNegZVisibility && (
        <DistanceMeasurement
          start={measurementRulerNegZ[0]}
          end={measurementRulerNegZ[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 512" width="22px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}

      {measurementsActive && activeMeshId && (
        <DistanceMeasurement start={heightRulerPos[0]} end={heightRulerPos[1]} description={'Height'} />
      )}
      {measurementsActive && activeMeshId && (
        <DistanceMeasurement start={widthRulerPos[0]} end={widthRulerPos[1]} description={'Width'} />
      )}
      {measurementsActive && activeMeshId && (
        <DistanceMeasurement start={depthRulerPos[0]} end={depthRulerPos[1]} description={'Depth'} />
      )}
    </>
  )
}

export default RoomPlanner
