import { StrictMode, Suspense, useRef, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Loader, Stats } from '@react-three/drei'

import useScene from './store/useScene'

import SidePanel from './components/side-panel'
import RoomPlanner from './components/core/RoomPlanner'
import PeerToPeerStream from './components/peer-to-peer-stream'
import FlipPhoneOnLandscape from './components/utils/FlipPhoneOnLandscape.jsx'
import { Toaster } from '@/components/ui/toaster'

const Planovise = () => {
  // basic logic
  const userId = useRef(Date.now().toString(36) + Math.random().toString(36).substring(2, 9))
  const { scene } = useScene()

  // meshes logic
  const [meshes, setMeshes] = useState([])
  const [activeMeshId, setActiveMeshId] = useState(0)
  const [measurementsActive, setMeasurementsActive] = useState(false)

  // video call
  const [peerIdToCall, setPeerIdToCall] = useState(null)

  // collab logic
  const [roomId, setRoomId] = useState(null)
  const [socketio, setSocketio] = useState(null)

  const handlePointerMissed = () => {
    setActiveMeshId(0)
  }

  return (
    <StrictMode>
      <div className="w-full h-full">
        <aside className="w-2/12 h-full fixed left-0">
          <SidePanel
            socketio={socketio}
            setSocketio={setSocketio}
            userId={userId}
            setPeerIdToCall={setPeerIdToCall}
            roomId={roomId}
            setRoomId={setRoomId}
            setMeshes={setMeshes}
            measurementsActive={measurementsActive}
            setMeasurementsActive={setMeasurementsActive}
          />
        </aside>
        <main className="w-10/12 h-full fixed right-0">
          <Canvas
            scene={scene}
            camera={{
              fov: 75,
              near: 0.1,
              far: 100,
              position: [5, 4, 5],
            }}
            background={'#fff'}
            shadows
            onPointerMissed={handlePointerMissed}
          >
            <Suspense fallback={null}>
              <RoomPlanner
                socketio={socketio}
                setSocketio={setSocketio}
                userId={userId}
                roomId={roomId}
                setRoomId={setRoomId}
                meshes={meshes}
                setMeshes={setMeshes}
                activeMeshId={activeMeshId}
                setActiveMeshId={setActiveMeshId}
                measurementsActive={measurementsActive}
              ></RoomPlanner>
            </Suspense>
          </Canvas>
        </main>
        {/* <Stats /> */}
        <Loader />
        <Toaster />
        <PeerToPeerStream peerIdToCall={peerIdToCall} setPeerIdToCall={setPeerIdToCall} />
        <FlipPhoneOnLandscape />
      </div>
    </StrictMode>
  )
}

export default Planovise
