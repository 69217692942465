import { useEffect, useRef } from 'react'
import { Html } from '@react-three/drei'

const DistanceMeasurement = ({ start, end, description }) => {
  const lineRef = useRef()
  const textRef = useRef()

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.geometry.setFromPoints([start, end])
    }
  }, [start, end])

  const distance = start.distanceTo(end)

  return (
    <>
      <line ref={lineRef}>
        <bufferGeometry />
        <lineBasicMaterial color="red" />
      </line>
      <Html position={start.clone().lerp(end, 0.5)} center>
        <div ref={textRef} className="text-red-600 text-sm absolute top-1 left-1">
          <div className="leading-none">{description}</div>
          {distance.toFixed(2)}m
        </div>
      </Html>
    </>
  )
}

export default DistanceMeasurement
