import useLoadedModels from '../../store/useLoadedModels'

const ModelIcon = ({ socketio, userId, roomId, modelIndex, modelIcon, setMeshes }) => {
  const { loadedModels } = useLoadedModels()

  return (
    <div
      onClick={() => {
        const clonedModel = loadedModels[modelIndex].clone()
        clonedModel.userData.hash += `-${clonedModel.id}`
        setMeshes((prevMeshes) => [...prevMeshes, clonedModel])
        if (roomId) {
          socketio.emit('itemAdd', {
            sender: userId.current,
            roomId: roomId,
            hash: clonedModel.userData.hash,
          })
        }
      }}
      className="w-16 h-16 rounded-md shadow-xl dark:shadow-gray-800"
    >
      <img
        className="w-full h-full opacity-80 transition duration-300 ease-in-out cursor-pointer hover:opacity-100 hover:scale-110 hover:shadow-lg"
        src={`data:image/png;${modelIcon}`}
      />
    </div>
  )
}

export default ModelIcon
