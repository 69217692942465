import * as THREE from 'three'

import { autoFitobject3DToCameraFrustum } from './utils'

export default class MeshSnapShooter {
  #renderer = null
  #camera = null
  #scene = null

  constructor() {
    this.#renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.#renderer.setClearColor(0x000000, 0)
    this.#renderer.outputEncoding = THREE.sRGBEncoding
    this.#renderer.setPixelRatio(window.devicePixelRatio || 1)
    this.#renderer.setSize(512, 512)

    this.#camera = new THREE.PerspectiveCamera(50, 1, 0.1, 100)
    this.#scene = new THREE.Scene()

    const topDirLight = new THREE.DirectionalLight(0xffffff, 1)
    this.#scene.add(topDirLight)

    const dirLight = new THREE.DirectionalLight(0xffffff, 3.0)
    dirLight.color.setHSL(0.1, 1, 0.95)
    dirLight.position.set(-1, 1.75, 1)
    dirLight.position.multiplyScalar(3)
    this.#scene.add(dirLight)

    this.#scene.add(new THREE.AmbientLight(0xf7f0d7, 2.5))
  }

  getPngOfMesh(mesh) {
    this.#scene.add(mesh)
    autoFitobject3DToCameraFrustum(mesh, this.#camera)

    this.#renderer.render(this.#scene, this.#camera)

    const dataUrl = this.#renderer.domElement.toDataURL('image/png')
    this.#scene.remove(mesh)

    return dataUrl
  }

  clear() {
    this.#renderer.dispose()
    this.#renderer = null
    this.#scene = null
    this.#camera = null
  }
}
